<template>
	<Metier name="procedes" title="Procédés" :main-menu="[{title: 'Ingénieur procédés', missions: ['Assure le suivi et l\'analyse des performances des unités et propose des solutions d\'optimisation', 'Vérifie la conformité des procédés avec les réglementations industrielles', 'Initie des projets de modification des unités en vue de les améliorer et de les développer', 'Participe aux études de danger et réalise des analyses de fiabilité', 'Analyse les incidents et rédige des rapports d\'expérience'], competences: ['Formation d\'Ingénieur en Génie Chimique ou Génie des Procédés', 'Bonne connaissance technique des procédés', 'Expérience des procédés de raffinage', 'Maîtrise des outils informatiques'], qualites: ['Rigueur', 'Organisation', 'Sens relationnel']}, {title: 'Technicien procédés', missions: ['Assure le suivi des unités et prépare des synthèses journalières', 'Crée, développe et utilise des moyens informatiques dédiés au suivi et à la résolution de problèmes sur les unités', 'Contrôle les bilans matières, signale les anomalies et informe les services concernés', 'Propose des améliorations techniques concernant la sécurité, la fiabilité et la valorisation'], competences: ['Connaissances en génie chimique et procédés', 'Expérience du raffinage ou de la pétrochimie', 'Maîtrise des outils informatiques'], qualites: ['Rigueur', 'Esprit d\'initiative', 'Créativité']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
